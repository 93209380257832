export const config = {
  listCounterClass: 'list-content__count',
  listCounterNumberClass: 'list-content__count-number',
  listWrapperClass: 'teaser-list__items-wrapper',
  sortDropdownLabelClass: 'list-content__toggle-sort-label',
  loadMoreButtonClass: 'js-load-more',
  itemParamsClasses: {
    sort: 'list-content__sort-link',
    link: 'list-filter__link',
    checkbox: 'checkbox__input',
    hashtag: 'list-filter__hashtag-item',
  },
  searchBar: {
    class: 'list-content__search-bar',
    minChars: 3, // mininum characters for suggestions
    doneTypingInterval: 400, // delay after typing before api is queried
  },
  excludeParamsFromUrl: [
    'docsOnly',
    'itemsPerPage',
    'language',
    'type',
  ],
};

export const urlWithParams = (params) => {
  const pageUrl = new URL(window.location.href);

  Object.keys(params).forEach((param) => {
    // eslint-disable-next-line
    if (config.excludeParamsFromUrl.indexOf(param) <= -1) {
      if (params[param] && params[param].length > 0) {
        pageUrl.searchParams.set(param, params[param]);
      } else {
        pageUrl.searchParams.delete(param);
      }
    }
  });

  return pageUrl;
};

export const detectItemType = ($item) => {
  let itemType = null;

  Object.keys(config.itemParamsClasses).forEach((key) => {
    const paramClass = config.itemParamsClasses[key];

    if ($item.classList.contains(paramClass)) {
      itemType = key;
    }
  });

  return itemType;
};

export const show = ($el) => {
  $el.classList.remove('x-hide');
};

export const hide = ($el) => {
  $el.classList.add('x-hide');
};

export const updateLoadMoreButton = ($loadMoreButton, page, itemsPerPage, totalResults) => {
  if (totalResults > (page * itemsPerPage)) {
    show($loadMoreButton);
  } else {
    hide($loadMoreButton);
  }
};
