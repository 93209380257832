import SwiperCore, { Swiper, Navigation, Pagination } from 'swiper/core';

/**
 * slider.js
 * https://swiperjs.com/swiper-api
 */

/* configure Swiper to use modules */
SwiperCore.use([Navigation, Pagination]);

/**
 * isSliderNecessary()
 *
 * Lets add a class if slider functionality is not needed
 * This way we can hide controls (arrow buttons etc.)
 */
const isSliderNecessary = (swiperObj, $swiperEl) => {
  if (swiperObj.isBeginning && swiperObj.isEnd) {
    $swiperEl.classList.add('slider--disabled');
  } else {
    $swiperEl.classList.remove('slider--disabled');
  }
};

const initSlider = ($swiperOuter) => {
  /* Detect all slider elements by class 'js-swiper' */
  const slidesPerView = $swiperOuter.getAttribute('data-slidesPerView');
  const $swiperInner = $swiperOuter.querySelector('.swiper-inner');
  const $swiperNextEl = $swiperOuter.querySelector('.swiper-arrow-next');
  const $swiperPrevEl = $swiperOuter.querySelector('.swiper-arrow-prev');

  const $thumbnails = $swiperOuter.querySelector('.slider__thumbnails');
  let sliderThumbnails = null;


  /* Init slider element with swiper.js */
  const sliderMain = new Swiper($swiperInner, { // eslint-disable-line
    // cssMode: true, // It's bugging when navigation back to first slide
    watchOverflow: true, // remove nav elements if to less items for slider
    slidesPerView: slidesPerView < 1.75 ? slidesPerView : 1.75,
    spaceBetween: 20,
    breakpoints: {
      '@0.75': {
        slidesPerView: slidesPerView < 3 ? slidesPerView : 3,
        spaceBetween: 24,

      },
      '@1.00': {
        slidesPerView: slidesPerView < 4 ? slidesPerView : 4,
        spaceBetween: 28,
      },
      '@1.50': {
        slidesPerView: slidesPerView < 5 ? slidesPerView : 5,
        spaceBetween: 32,
      },
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: $swiperNextEl,
      prevEl: $swiperPrevEl,
      disabledClass: 'slider__arrow--disabled',
    },
    autoHeight: true,
    observer: true,
    observeSlideChildren: true,
    preloadImages: true,
    updateOnImagesReady: true,
  });

  sliderMain.on('observerUpdate', () => {
    sliderMain.init();
    sliderMain.update();
  });

  isSliderNecessary(sliderMain, $swiperOuter);

  window.addEventListener('resize', () => {
    isSliderNecessary(sliderMain, $swiperOuter);
  });

  /**
   * Thumbnails
   */
  if ($thumbnails) {
    const $thumbnailsInner = $thumbnails.querySelector('.swiper-inner');
    const $thumbnailsNextEl = $thumbnails.querySelector('.swiper-arrow-next');
    const $thumbnailsPrevEl = $thumbnails.querySelector('.swiper-arrow-prev');

    sliderThumbnails = new Swiper($thumbnailsInner, {
      spaceBetween: 4,
      slidesPerView: 2.5,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: $thumbnailsNextEl,
        prevEl: $thumbnailsPrevEl,
        disabledClass: 'slider__arrow--disabled',
      },
      breakpoints: {
        '@0.75': {
          slidesPerView: 4.5,
          spaceBetween: 8,

        },
        '@1.00': {
          slidesPerView: 6,
          spaceBetween: 16,
        },
        '@1.50': {
          slidesPerView: 6,
          spaceBetween: 32,
        },
      },
    });

    // Initially activate first thumbnail slide
    sliderThumbnails.slides[0].classList.add('slider__slide--active');

    isSliderNecessary(sliderThumbnails, $thumbnails.querySelector('.js-thumbnails'));

    window.addEventListener('resize', () => {
      isSliderNecessary(sliderThumbnails, $thumbnails.querySelector('.js-thumbnails'));
    });

    // Sync both slider
    const $thumbnailSlides = $thumbnailsInner.querySelectorAll('.slider__slide');

    $thumbnailSlides.forEach(($slide) => {
      $slide.addEventListener('click', () => {
        const clickIndex = $slide.getAttribute('data-index');

        sliderMain.slideTo(clickIndex);
      });
    });

    sliderMain.on('activeIndexChange', () => {
      const newIndex = sliderMain.activeIndex;

      sliderThumbnails.slideTo(newIndex);

      sliderThumbnails.slides.forEach((slide, index) => {
        if (newIndex === index) {
          slide.classList.add('slider__slide--active');
        } else {
          slide.classList.remove('slider__slide--active');
        }
      });
    });
  }
};

export default initSlider;
