import tippy from 'tippy.js';

tippy('.js-popover-link', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  allowHTML: true,
  trigger: 'click',
  theme: 'light',
  placement: 'right',
  interactive: true,
  duration: 100,
  // offset: [130, 20],
});
