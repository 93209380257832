import lightbox from 'javascripts/globals/lightbox';
import Cookies from 'js-cookie';

const initPopupModals = () => {
  document.querySelectorAll('.js-popup-modal').forEach(() => {
    const modalCookie = Cookies.get('popup-modal');
    if (modalCookie !== 'true') {
      const $modalContent = document.querySelector('#popup-modal');
      if ($modalContent) {
        lightbox($modalContent.innerHTML, { fadeIn: true, transparent: true });
        Cookies.set('popup-modal', 'true', { expires: 7 });
      }
    }
  });
};

initPopupModals();

export default initPopupModals;
