import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';

function Basket() {
  const $basket = document.querySelector('.js-basket');

  if ($basket) {
    const cookie = $basket.dataset.cookie; // eslint-disable-line
    const link = $basket.dataset.link; // eslint-disable-line
    const text = $basket.dataset.text || 'Artikel im Warenkorb';

    const getCookie = (name) => {
      const value = `;${document.cookie}`;
      const parts = value.split(`;${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return null;
    };

    if (cookie && link && getCookie(cookie)) {
      const $basketLink = h('a.basket', { href: link, title: `${getCookie(cookie)} ${text}` }, generateIcon({
        icon: 'books',
        classes: ['basket__icon'],
      }), h('span.basket__count', getCookie(cookie), h('span.u-visually-hidden', text)));

      $basket.appendChild($basketLink);
    }
  }
}

Basket();
