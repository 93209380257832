import lightbox from 'javascripts/globals/lightbox';
import resolveTemplate from './person-box-template-resolver';

async function getApiData($target, url, callback) {
  try {
    const resp = await fetch(url);
    const data = await resp.json();

    callback(data, $target);
  } catch (err) {
    throw (err);
  }
}

const fillTemplateWithApiData = (data, $target) => {
  // Resolve template with API data
  const $templateWrapper = resolveTemplate(data);

  // Initialize lightbox with $templateWrapper as content
  lightbox($templateWrapper, { fadeIn: true }, $target);
};

const initPersonBoxDialog = () => {
  document.querySelectorAll('.js-person-box-dialog').forEach(($personBoxDialog) => {
    $personBoxDialog.addEventListener('click', (e) => {
      e.preventDefault();

      if (!$personBoxDialog.classList.contains('active')) {
        const apiUrl = $personBoxDialog.getAttribute('data-api-url');

        getApiData($personBoxDialog, apiUrl, fillTemplateWithApiData);
      }
    });
  });
};

initPersonBoxDialog();

export default initPersonBoxDialog;
