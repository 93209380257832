import initMapbox from './map.mapbox';

// const initMapMarker = ($map) => {
//   const $mapMarkers = $map.querySelectorAll('.js-map__marker');

//   $mapMarkers.forEach($mapMarker => {
//     $mapMarker.addEventListener('click', () => {
//       const isActive = $mapMarker.getAttribute('aria-selected') === 'true';

//       if (!isActive) {
//         const targetSlug = $mapMarker.getAttribute('aria-controls');

//         $mapMarkers.forEach(($mapMarker) => {
//           $mapMarker.removeAttribute('aria-selected');
//         });

//         document.body.querySelector(`[data-slug=${targetSlug}]`).click();
//         $mapMarker.setAttribute('aria-selected', 'true');
//       }
//     });
//   });
// };

// const $maps = document.body.querySelectorAll('.js-map');
const $mapboxes = document.body.querySelectorAll('.js-mapbox');

// $maps.forEach($map => initMapMarker($map));
$mapboxes.forEach($mapbox => initMapbox($mapbox));
