import mapboxgl from 'mapbox-gl';
import h from 'hyperscript';

// eslint-disable-next-line
const accessToken = 'pk.eyJ1IjoicnNtdGVjaCIsImEiOiJja3B3dHF3cmYwMDhxMnZxbDh6eDRhemU4In0.JxPuiUlCrwlSRwJw5XGUjA';

function getBoundingBox(data) {
  const bounds = {};
  let coords;
  let latitude;
  let longitude;

  for (let i = 0; i < data.length;) {
    coords = data[i].coordinates;

    for (let j = 0; j < coords.length;) {
      longitude = coords[0]; // eslint-disable-line
      latitude = coords[1]; // eslint-disable-line
      bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
      bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
      bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
      bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;

      j += 1;
    }

    i += 1;
  }

  return bounds;
}

const initMapbox = ($mapBox) => {
  const mapBoxId = $mapBox.getAttribute('id');
  const mapBoxMarker = $mapBox.getAttribute('data-marker');
  const mapBoxSettings = JSON.parse($mapBox.getAttribute('data-settings'));
  const geojson = JSON.parse(mapBoxMarker);

  mapboxgl.accessToken = accessToken;

  const map = new mapboxgl.Map({
    container: mapBoxId,
    style: 'mapbox://styles/mapbox/streets-v10',
    // eslint-disable-next-line
    center: mapBoxSettings && mapBoxSettings.center ? mapBoxSettings.center : geojson[0].coordinates,
    zoom: mapBoxSettings && mapBoxSettings.zoom ? mapBoxSettings.zoom : 1,
    compact: true,
  });

  const lngLatBounds = [];

  // add markers to map
  geojson.forEach((marker) => {
    lngLatBounds.push(marker.coordinates);

    const el = h('.map__marker', { 'aria-controls': marker.controls },
      h('map__marker-inner',
        h('button.map__marker-button')));

    if (marker.active === true) {
      el.querySelector('.map__marker-button').setAttribute('aria-selected', 'true');
    }

    // eslint-disable-next-line
    el.querySelector('.map__marker-button').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 62"><path d="M4.47978 54.4C2.01626 54.4 0 52.35973 0 49.86687V4.53313C0 2.04027 2.01626 0 4.47978 0h47.04044C53.98414 0 56 2.04027 56 4.53313v45.33374C56 52.35973 53.98414 54.4 51.52022 54.4H36l-7.8 7.2-7.8-7.2H4.47978z"/><path fill="#FFFFFE" d="M9 41h2V13H9zM17.11287 27.70917v5.57136H20.489c2.40691 0 3.43066-1.1947 3.43066-2.89194 0-1.65244-1.1325-2.67942-3.4415-2.67942h-3.36529zm0-1.54095h2.78803c2.38523 0 3.47436-1.11656 3.47436-2.73566 0-1.87575-1.07828-2.7242-3.39782-2.7242h-2.86457v5.45986zM15 35V19h4.97744c3.83347 0 5.49984 1.58542 5.49984 4.1759 0 1.5743-.95868 2.97009-2.92963 3.59523C24.82381 27.22887 26 28.44581 26 30.4445 26 33.1576 24.00673 35 20.52185 35H15zM36.8805 22.35274c-.21528.34392-.41983.51573-.79646.51573-.65652 0-1.46369-1.01007-3.0566-1.01007-1.36691 0-2.02343.62335-2.02343 1.6117 0 2.53588 6.99599 1.0963 6.99599 6.33984C38 32.70044 35.95482 35 32.38165 35c-1.9481 0-4.15467-.83795-5.38165-2.06293l1.07633-1.69793c.16137-.25802.463-.42983.79645-.42983.85035 0 1.65752 1.3109 3.61635 1.3109 1.32406 0 2.08805-.55854 2.08805-1.81593 0-2.9443-6.97425-.80585-6.97425-6.65135 0-2.36407 1.93708-4.65293 5.38134-4.65293 1.92668 0 3.68095.63405 4.80046 1.66551l-.90424 1.68723M41.57847 31.64335v-5.93798h-.95164c-.34602 0-.62683-.2326-.62683-.67574v-1.34046l1.78393-.35458.65944-2.80256c.08643-.35458.33494-.53203.7351-.53203h1.75133v3.35698h2.7245v2.3484h-2.7245v5.70536c0 .53171.25928.91938.7351.91938.49735 0 .6053-.25466.88643-.25466.20546 0 .30265.08856.43245.2991L48 34.0031c-.8649.66472-1.97831.99691-3.1351.99691-2.11888 0-3.28643-1.3184-3.28643-3.35665"/></svg>';

    // make a marker for each feature and add to the map
    new mapboxgl.Marker(el)
      .setLngLat(marker.coordinates)
      .addTo(map);

    el.addEventListener('click', () => {
      const isActive = el.getAttribute('aria-selected') === 'true';

      if (!isActive) {
        document.body.querySelectorAll('.map__marker').forEach(($el) => {
          $el.querySelector('.map__marker-button').removeAttribute('aria-selected');
        });

        const $target = document.body.querySelector(`[data-slug=${marker.controls}]`);

        if ($target) {
          $target.click();
        }

        el.querySelector('.map__marker-button').setAttribute('aria-selected', 'true');
      }
    });
  });

  const boundingBox = getBoundingBox(geojson);

  if (geojson.length > 1) {
    map.fitBounds([[boundingBox.xMin, boundingBox.yMin], [boundingBox.xMax, boundingBox.yMax]], {
      padding: {
        top: 100,
        bottom: 70,
        left: 50,
        right: 50,
      },
    });
  }

  map.scrollZoom.disable(); // disable map zoom when using scroll
  map.dragRotate.disable(); // disable map rotation using right click + drag
  map.touchZoomRotate.disableRotation(); // disable map rotation using touch rotation gesture
  map.boxZoom.disable();
  map.doubleClickZoom.disable();
  map.dragPan.disable();
};

export default initMapbox;
