import h from 'hyperscript';
import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import getTarget from 'javascripts/utils/get-target';

const createBackgroundContainer = (ariaHidden = 'true') => {
  const backgroundContainerClass = 'overlay-background';
  const $backgroundContainer = document.body.querySelector(`.${backgroundContainerClass}`);

  if (!$backgroundContainer) {
    document.body.appendChild(h(`.${backgroundContainerClass}`, { ariaHidden: ariaHidden })); // eslint-disable-line
  } else {
    document.body.querySelector(`.${backgroundContainerClass}`).setAttribute('aria-hidden', ariaHidden);
  }
};

const toggler = ($toggler, settings = null) => {
  const targetName = $toggler.getAttribute('aria-controls');
  const $target = document.querySelector(`#${$toggler.getAttribute('aria-controls')}`);
  const focusClass = (settings && settings.focusTrap.class) ? `.${settings.focusTrap.class}` : `#${$toggler.getAttribute('aria-controls')}`;
  const $focusElement = (settings && settings.focusTrap.class) ? document.querySelector(`.${settings.focusTrap.class}`) : $target;

  if (settings.background) {
    createBackgroundContainer();
  }

  // Define FocusTrap
  const focusTrap = createFocusTrap($focusElement, {
    initialFocus: settings.focusTrap.initialFocus,
    escapeDeactivates: true,
    clickOutsideDeactivates: settings.focusTrap.clickOutsideDeactivates || false,
    onActivate: () => {
      if (settings.focusTrap.initialFocus) {
        setTimeout(() => {
          settings.focusTrap.initialFocus.focus();
        }, 100);
      }

      if (settings.disableBodyScroll) {
        disableBodyScroll(document.body);
      }

      if (settings.background) {
        createBackgroundContainer('false');
      }
    },
    onDeactivate: () => {
      if (settings.focusTrap.onDeactivate) {
        setTimeout(() => {
          settings.focusTrap.onDeactivate();
        }, 300);
      }

      if (settings.disableBodyScroll) {
        clearAllBodyScrollLocks();
      }

      if (settings.addBodyClass) {
        document.body.classList.remove(`${targetName}--active`);
      }

      if (settings.background) {
        createBackgroundContainer('true');
      }
    },
  });

  $toggler.addEventListener('click', () => {
    const isExpanded = $toggler.getAttribute('aria-expanded') === 'true';

    if (settings.addBodyClass) {
      document.body.classList.toggle(`${targetName}--active`);
    }

    $toggler.setAttribute('aria-expanded', !isExpanded);
    $target.setAttribute('aria-hidden', !!isExpanded);

    if (isExpanded === true) {
      focusTrap.deactivate();
    } else {
      focusTrap.activate();
    }

    const close = () => {
      document.body.querySelector('.header').classList.remove('header--nav-active');
      $toggler.setAttribute('aria-expanded', 'false');
      $target.setAttribute('aria-hidden', 'true');
      focusTrap.deactivate();
    };

    document.body.addEventListener('click', (event) => {
      const targetInFocus = getTarget(event.target, focusClass);

      if (!targetInFocus) {
        close();
      }
    });

    document.onkeydown = (e) => {
      const evt = e || window.event;
      let isEscape = false;

      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }

      if (isEscape) {
        close();
      }
    };
  });
};

export default toggler;
