import h from 'hyperscript';
import { autoLink } from 'twitter-text/dist/index';

async function twitterTimeline() {
  const $container = document.querySelectorAll('.js-tweets');

  if ($container) {
    $container.forEach(async ($content) => {
      // Get tweets
      const response = await fetch($content.dataset.api);
      const tweets = await response.json();
      const $list = h('.twitter-timeline__list');

      // Render tweets
      tweets.forEach((tweet) => {
        const text = autoLink(tweet.text, {
          hashtagClass: 'link',
          cashtagClass: 'link',
          listClass: 'link',
          usernameClass: 'link',
          urlClass: 'link',
          urlEntities: tweet.entities.urls,
        });

        $list.appendChild(h(
          'article.twitter-timeline__item',
          h('p.twitter-timeline__item-text.text.text--small', document.createRange().createContextualFragment(text)),
          h('p.twitter-timeline__item-date', tweet.created_at),
        ));
      });

      $content.appendChild($list);
    });
  }
}

// Init on page load
twitterTimeline();
