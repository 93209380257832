import { LANG } from 'javascripts/constants';

export const defaultParams = {
  q: '',
  language: LANG,
  type: 'search', // search, topic-pages-index, topic-pages-documents,
  typeName: '',
  filter: '',
  sort: '',
  page: 1,
  itemsPerPage: 10,
  docsOnly: true,
};

/**
 * Tranform string values of a json object
 * "true" / "false" to boolean
 * "1" etc. to number
 */
export const normalizeObject = (obj) => {
  const newObj = obj;

  // eslint-disable-next-line
  Object.keys(obj).forEach(key => {
    // eslint-disable-next-line
    if (obj[key] && obj[key].length > 0 && !isNaN(obj[key])) {
      newObj[key] = Number(obj[key]);
    } else {
      newObj[key] = obj[key];
    }

    if (obj[key] === 'true' || obj[key] === 'false') {
      newObj[key] = (obj[key] === 'true');
    }

    if (obj[key] === null) {
      newObj[key] = '';
    }
  });

  return newObj;
};

const getContentFromAPI = (params, api = null, callback) => {
  const xhttp = new XMLHttpRequest();
  xhttp.open('POST', api || 'https://www.bertelsmann-stiftung.de/de/system/suchergebnisseite?reloadSearch=1', true);
  // eslint-disable-next-line
  xhttp.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      const data = JSON.parse(this.responseText);
      callback(undefined, data, this.status);
    } else if (this.readyState === 4) {
      callback('could not fetch data', undefined, this.status);
    }
  };

  xhttp.send(JSON.stringify(normalizeObject(params)));

  console.log('API parameters: ', normalizeObject(params)); // eslint-disable-line
};

export default getContentFromAPI;
