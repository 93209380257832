import toggler from 'javascripts/utils/toggler';
import { resetSearchBar } from 'components/organisms/search-bar/search-bar';

const $searchButton = document.querySelector('.header__search-btn');

const $languageButton = document.querySelector('.header__language-btn');
const $menuButton = document.querySelector('.header__menu-btn');

if ($searchButton) {
  toggler($searchButton, {
    focusTrap: {
      class: 'header__search-wrapper',
      initialFocus: document.querySelector('.header__search-wrapper .input'),
      clickOutsideDeactivates: true,
      onDeactivate: () => {
        resetSearchBar(document.querySelector('.header__search-wrapper'));
      },
    },
    background: true,
    disableBodyScroll: true,
  });
}

if ($languageButton) {
  toggler($languageButton, {
    focusTrap: {
      class: 'header__language-wrapper',
      clickOutsideDeactivates: true,
    },
  });
}

if ($menuButton) {
  toggler($menuButton, {
    focusTrap: {
      class: 'header__navigation',
    },
    addBodyClass: true,
    disableBodyScroll: true,
  });
}
