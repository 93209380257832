import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const lightboxClose = ($wrapper, focusTrap, $target = null) => {
  $wrapper.classList.add('lightbox--closing');
  enableBodyScroll(document.body);
  focusTrap.deactivate();
  if ($target) {
    $target.classList.remove('active');
  }

  setTimeout(() => {
    $wrapper.remove();
  }, 500);
};

const initLightboxControls = ($wrapper, focusTrap, $target = null) => {
  $wrapper.querySelector('.lightbox__close').addEventListener('click', () => {
    lightboxClose($wrapper, focusTrap, $target);
  });

  $wrapper.querySelector('.lightbox__background').addEventListener('click', () => {
    lightboxClose($wrapper, focusTrap, $target);
  });
};

const lightbox = ($content, settings = null, $target = null) => {
  if (!$target || !$target.classList.contains('active')) {
    const $lightboxTemplate = document.body.querySelector('.lightbox-template');
    const $lightboxClone = $lightboxTemplate.content.cloneNode(true);
    const $lightboxCloneInner = $lightboxClone.querySelector('.lightbox');

    // Define FocusTrap
    const focusTrap = createFocusTrap($lightboxClone.querySelector('.lightbox'), {
      escapeDeactivates: true,
    });

    if ($target) {
      $target.classList.add('active');
    }

    // Place content into lightbox. (Both strings and HTML objects work.)
    $lightboxClone.querySelector('.lightbox__content').innerHTML = $content.outerHTML || $content;

    // Set unique id for lightbox
    if (settings.id) {
      $lightboxClone.querySelector('.lightbox').setAttribute('id', `lightbox-${settings.id}`);
    }

    // Check settings for animation
    if (settings.fadeIn === true) {
      $lightboxClone.querySelector('.lightbox').classList.add('lightbox--fadein');
    }

    // Check settings for style
    if (settings.transparent === true) {
      $lightboxClone.querySelector('.lightbox').classList.add('lightbox--transparent');
    }

    // Activate controls for lightbox
    initLightboxControls($lightboxClone.querySelector('.lightbox'), focusTrap, $target);

    // Put lightbox markup into DOM
    document.body.appendChild($lightboxClone.querySelector('.lightbox'));

    // Disable scrolling on page
    disableBodyScroll(document.body);

    // Enable focus trap
    focusTrap.activate();

    document.onkeydown = (e) => {
      const evt = e || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = (evt.key === 'Escape' || evt.key === 'Esc');
      } else {
        isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
        lightboxClose($lightboxCloneInner, focusTrap, $target);
      }
    };
  }
};

export default lightbox;
