import h from 'hyperscript';
import generateIcon from 'components/_particles/icon/icon';

const replaceWithData = ($el, $replaceString, data) => {
  if (data && $el) {
    const HTML = $el.innerHTML.replaceAll($replaceString, data);
    $el.innerHTML = HTML;
  } else if ($el) {
    $el.remove();
  }
};

const resolveTemplate = (data) => {
  const $template = document.querySelector('.person-box__detail').innerHTML;
  const $templateWrapper = h('div');

  $templateWrapper.innerHTML = $template || '';

  // Name
  const $name = $templateWrapper.querySelector('.js-name');
  replaceWithData($name, '%NAME%', data.name.text || null);

  // Role
  const $role = $templateWrapper.querySelector('.js-role');
  replaceWithData($role, '%POSITION%', data.role.text || null);

  // Slogan
  const $slogan = $templateWrapper.querySelector('.js-slogan');
  replaceWithData($slogan, '%SLOGAN%', data.slogan.text || null);

  // Image
  const $image = $templateWrapper.querySelector('.js-image');
  if (data.image) {
    $image.innerHTML = `<img src="${data.image.src}" width="${data.image.width}" height="${data.image.height}" alt="${data.image.alt}" class="person-detail__img"/>`;
  }

  // Contact
  const $contacts = $templateWrapper.querySelector('.js-contacts');
  const $contactsLink = $templateWrapper.querySelector('.js-contacts-link');

  // Prepare downloads
  $contacts.innerHTML = '';

  if (data.contacts) {
    // Prepare section items
    data.contacts.items.forEach((item) => {
      if (item.link) {
        const $link = $contactsLink;

        const $contactNewIcon = generateIcon({
          icon: item.icon,
          title: item.title,
        });
        $link.querySelector('.icon').replaceWith($contactNewIcon);

        if (item.external) {
          $link.querySelector('.icon-link').setAttribute('target', '_blank');
          $link.querySelector('.icon-link').setAttribute('rel', 'noopener noreferrer');
        }

        const $contactLinkNew = $link.outerHTML
          .replaceAll('%CONTACTS_TEXT%', item.text)
          .replaceAll('%CONTACTS_LINK%', item.link);

        $contacts.innerHTML += $contactLinkNew;
      }
    });
  }

  // Downloads
  const $downloads = $templateWrapper.querySelector('.js-downloads');
  const $downloadsTitle = $templateWrapper.querySelector('.js-downloads-title');
  const $downloadsLink = $templateWrapper.querySelector('.js-downloads-link');

  // Prepare downloads
  $downloads.innerHTML = '';

  if (data.downloads) {
    // Prepare downloads title
    if (data.downloads.title) {
      const $downloadsTitleNew = $downloadsTitle.outerHTML
        .replaceAll('%DOWNLOADS_TITLE%', data.downloads.title);

      $downloads.innerHTML += $downloadsTitleNew;
    } else {
      $downloadsTitle.remove();
    }

    // Prepare section items
    data.downloads.items.forEach((item) => {
      if (item.link) {
        const $link = $downloadsLink;
        // const $icon = $downloadsIcon;

        const $downloadNewIcon = generateIcon({
          icon: item.icon,
          title: item.title,
        });
        $link.querySelector('.icon').replaceWith($downloadNewIcon);

        const $downloadLinkNew = $link.outerHTML
          .replaceAll('%DOWNLOADS_TEXT%', item.text)
          .replaceAll('%DOWNLOADS_LINK%', item.link)
          .replaceAll('%DOWNLOADS_DOWNLOAD%', item.download);

        $downloads.innerHTML += $downloadLinkNew;
      }
    });
  }

  // Sections
  const $sections = $templateWrapper.querySelector('.js-sections');
  const $section = $templateWrapper.querySelector('.js-section');
  const $sectionTitle = $templateWrapper.querySelector('.js-section-title');
  const $sectionText = $templateWrapper.querySelector('.js-text');
  const $sectionLink = $templateWrapper.querySelector('.js-link');
  const $sectionLinkExternal = $templateWrapper.querySelector('.js-link-external');

  // Prepare sections
  $sections.innerHTML = '';

  if (data.sections) {
    data.sections.forEach((section) => {
      const $sectionClone = $section.cloneNode(true);
      $sectionClone.innerHTML = '';

      // Prepare section title
      if (section.title) {
        const $sectionTitleNew = $sectionTitle.outerHTML
          .replaceAll('%SECTION_TITLE%', section.title);

        $sectionClone.innerHTML += $sectionTitleNew;
      } else {
        $sectionTitle.remove();
      }

      // Prepare section items
      section.items.forEach((item) => {
        if (item.link) {
          const $link = item.external === true ? $sectionLinkExternal : $sectionLink;
          const $sectionLinkNew = $link.outerHTML
            .replaceAll('%ITEM_TEXT%', item.text)
            .replaceAll('%ITEM_LINK%', item.link);

          $sectionClone.innerHTML += $sectionLinkNew;
        } else {
          const $sectionTextNew = $sectionText.outerHTML
            .replaceAll('%ITEM_TEXT%', item.text);

          $sectionClone.innerHTML += $sectionTextNew;
        }
      });

      $sections.appendChild($sectionClone);
    });
  } else {
    $sections.remove();
  }

  return $templateWrapper;
};

export default resolveTemplate;
