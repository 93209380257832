import h from 'hyperscript';

const templateResolver = ({ sliderItems }) => {
  const $template = document.body.querySelector('.media-lightbox-template');
  const $templateClone = $template.content.cloneNode(true);
  const $captionsWrapperClone = $templateClone.querySelector('.figure__caption');

  // Create slider wrap
  const $newSliderWrapper = h('div.slider__wrap.swiper-wrapper',
    // Create single slide
    sliderItems.map((sliderItem, index) => h('div.slider__slide.swiper-slide',
      h('div.slider__slide-inner', { 'data-index': index }, () => {
        const $figure = h('figure');
        const figureCaption = $captionsWrapperClone.cloneNode(true) || null;
        let figureMedia = null;

        // Create Soundcloud
        if (sliderItem.soundcloud) {
          figureMedia = h('div.figure__media.figure__media--soundcloud', { tabIndex: 0 },
            h('div.figure__iframe-wrapper', { 'data-url': sliderItem.soundcloud.url }));
        } else if (sliderItem.video) {
          figureMedia = h('div.figure__media.figure__media--video', { tabIndex: 0 },
            h('div.figure__iframe-wrapper', { 'data-url': sliderItem.video.url }));
        } else if (sliderItem.image) {
          figureMedia = h('div.slider__slide-image', { tabIndex: 0 },
            h('img', { src: sliderItem.image.url }));
        }

        // Set up caption area
        let $captionWrapper = null;

        if (sliderItem.caption && figureCaption) {
          let copyright = '';
          let caption = '';

          if (sliderItem.caption.copyright) {
            const copyrightText = figureCaption.querySelector('.figure__copyright').cloneNode(true);
            copyright = copyrightText.outerHTML.replaceAll('%COPYRIGHT%', sliderItem.caption.copyright);
          }

          if (sliderItem.caption.text) {
            const captionText = figureCaption.querySelector('.figure__caption-text').cloneNode(true);
            caption = captionText.outerHTML.replaceAll('%CAPTION%', sliderItem.caption.text);
          }

          if (copyright || caption) {
            $captionWrapper = h('figcaption.figure__caption');
            $captionWrapper.innerHTML = copyright + caption;
          }
        }

        // Merge items for slide
        if (figureMedia && $captionWrapper) {
          $figure.innerHTML = figureMedia.outerHTML + $captionWrapper.outerHTML;
        } else if (figureMedia) {
          $figure.innerHTML = figureMedia.outerHTML;
        }

        return $figure;
      }))));

  // Merge slides into template
  $templateClone.querySelector('.media-teaser__gallery>.slider>.slider__inner').innerHTML = $newSliderWrapper.outerHTML;

  // Create thumbnails
  const $newThumbnailsWrapper = h('div.slider__wrap.swiper-wrapper',
    sliderItems.map((sliderItem, index) => h('button.slider__slide.swiper-slide', { 'data-index': index, tabIndex: 0 },
      h('div.slider__slide-inner',
        h('div.slider__slide-image',
          h('img', { src: sliderItem.thumbnail || '' }))))));

  // Show thumbnails only if there is more than one slide
  if (sliderItems.length > 1) {
    $templateClone.querySelector('.media-teaser__gallery .slider__thumbnails .slider__inner').innerHTML = $newThumbnailsWrapper.outerHTML;
  } else {
    $templateClone.querySelector('.media-teaser__gallery .slider__thumbnails').remove();
  }

  const $content = $templateClone.querySelector('.media-teaser__gallery');

  return $content;
};

export default templateResolver;
