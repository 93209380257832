import initListContentHandler from './list-content.handler';

class ListToggle {
  constructor($el) {
    // Elements
    this.$el = $el;

    // Add events
    if (this.$el) {
      this.$el.addEventListener('click', this.onToggleClick.bind(this));
    }
  }

  onToggleClick() {
    const $content = document.getElementById(this.$el.getAttribute('aria-controls'));
    const contentVisible = this.$el.getAttribute('aria-expanded') === 'true';
    const visibleClass = 'list-content__toggle-content--visible';

    this.$el.setAttribute('aria-expanded', contentVisible ? 'false' : 'true');

    if (contentVisible) {
      $content.classList.remove(visibleClass);
    } else {
      $content.classList.add(visibleClass);
    }
  }
}

document.querySelectorAll('.js-list-toggle').forEach($el => new ListToggle($el));
document.querySelectorAll('.js-list-content').forEach($el => initListContentHandler($el));
