import lightbox from 'javascripts/globals/lightbox';

const initModals = () => {
  document.querySelectorAll('.js-with-modal').forEach(($el) => {
    const $modalToggle = $el.querySelector('.js-modal-toggle');

    if ($modalToggle) {
      const templateId = $modalToggle.getAttribute('aria-controls');

      if (templateId) {
        const $modalContent = document.querySelector(`#${templateId}`);

        if ($modalContent) {
          $modalToggle.addEventListener('click', (e) => {
            e.preventDefault();

            lightbox($modalContent.innerHTML, { fadeIn: true, transparent: true });
          });
        }
      }
    }
  });
};

initModals();

export default initModals;
