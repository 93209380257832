import h from 'hyperscript';
import getVideoId from 'get-video-id';

const initIframe = ($el) => {
  const url = $el.getAttribute('data-url');
  const { id, service } = getVideoId(url);

  let $iframeContent = null;

  if (id && service === 'youtube') {
    // Construct Youtube iframe player, add rel=0 to disable related videos
    $iframeContent = h('iframe.figure__iframe-video', {
      src: `https://www.youtube-nocookie.com/embed/${id}?rel=0&autoplay=0`,
      allowFullscreen: true,
      msAllowFullscreen: true,
      webkitAllowFullscreen: true,
      mozAllowFullscreen: true,
    });
  } else if (id && service === 'vimeo') {
    // Construct Vimeo iframe player
    $iframeContent = h('iframe.figure__iframe-video', {
      src: `https://player.vimeo.com/video/${id}`,
      allowFullscreen: true,
      msAllowFullscreen: true,
      webkitAllowFullscreen: true,
      mozAllowFullscreen: true,
    });
  } else if (url.indexOf('soundcloud') > -1) {
    $iframeContent = h('iframe.figure__iframe-audio', {
      src: `https://w.soundcloud.com/player/?url=${url}`,
    });
  }

  if ($iframeContent) {
    $el.appendChild($iframeContent);
  }
};

export default initIframe;
