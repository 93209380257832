import h from 'hyperscript';
import { LANG } from 'javascripts/constants';

const config = {
  apiUrl: 'https://www.bertelsmann-stiftung.de/index.php',
  minChars: 3, // mininum characters for suggestions
  doneTypingInterval: 200, // delay after typing before api is queried
  teaserTextLength: 120, // maximum chars length for teasers
  maxResults: 3, // Results to be shown from the API
};

const resetList = ($el) => {
  const $list = $el.querySelector('.search-bar__list');
  if ($list) {
    $list.remove();
  }
};

const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
};

const getItemUrl = (item, type) => {
  let itemUrl = 'https://www.bertelsmann-stiftung.de';

  if (type === 'entities') {
    itemUrl += `/${LANG}/system/suchergebnisseite?tx_rsmsearch_pi1[searchQuery][queryString]=${encodeURIComponent(item.entity_name)}`;
  }

  if (type === 'topic_pages') {
    itemUrl += `/${LANG}/tag/${encodeURIComponent(item.doc_id)}`;
  }

  if (type === 'projects') {
    itemUrl += `${item.payload.linkForReadMore}`;
  }

  if (type === 'publications') {
    const parameter = item.payload && item.payload.parameter;

    if (parameter.indexOf('[uid]') >= 0) {
      const parameterId = parameter.split('[uid]=')[1].split('&')[0];
      itemUrl += `/index.php?id=22&tx_rsmbstpublications_pi2[itemuid]=${parameterId}&no_cache=1`;
    } else {
      itemUrl = parameter;
    }
  }

  return itemUrl;
};

const createSearchBarLinkList = (items, type) => {
  let output;

  if (items.length > 0) {
    output = h('ul.search-bar__list', { ariaLabel: 'Suchvorschläge' }, items.map((item, index) => {
      let $item;

      if (index < config.maxResults) {
        $item = h('li.search-bar__item',
          h('a.search-bar__link', {
            href: getItemUrl(item, type),
          },
          item.title || item.entity_name));
      }

      return $item;
    }));
  } else {
    output = h('p.search-bar__list.search-bar__list--empty', 'Keine Ergebnisse');
  }

  return output;
};

const createSearchBarTeaserList = (items, type) => {
  let output;

  if (items.length > 0) {
    output = h('div.search-bar__list', { ariaLabel: 'Suchvorschläge' }, items.map((item, index) => {
      let $item;

      if (index < config.maxResults) {
        $item = h('article.search-bar__teaser',
          h('h3.headline.headline--3.search-bar__item-headline',
            h('a.headline__link.search-bar__link', {
              href: getItemUrl(item, type),
            }, decodeHTMLEntities(item.title))),
          h('p.search-bar__description', () => {
            let teaserText = decodeHTMLEntities(item.teaser);

            if (teaserText.length > config.teaserTextLength) {
              teaserText = `${teaserText.slice(0, config.teaserTextLength)}...`;
            }

            return teaserText;
          }));
      }

      return $item;
    }));
  } else {
    output = h('p.search-bar__list.search-bar__list--empty', 'Keine Ergebnisse');
  }

  return output;
};

const initSearchBars = () => {
  const $searchBars = document.body.querySelectorAll('.js-search-bar');
  const language = document.querySelector('html').getAttribute('lang');

  $searchBars.forEach(($searchBar) => {
    const $input = $searchBar.querySelector('.input');
    const $suggestionsContainer = $searchBar.querySelector('.search-bar__suggestions');
    const $entitiesCol = $searchBar.querySelector('.search-bar__entities');
    const $topicPagesCol = $searchBar.querySelector('.search-bar__tags');
    const $projectsCol = $searchBar.querySelector('.search-bar__projects');
    const $publicationsCol = $searchBar.querySelector('.search-bar__publications');
    const eventList = ['paste', 'cut', 'keyup'];

    let typingTimer;
    let oldValue;

    eventList.forEach((eventEl) => {
      $input.addEventListener(eventEl, (e) => {
        const inputValue = encodeURIComponent(e.target.value.trim());

        if (inputValue.length < config.minChars) {
          // Hide suggestions container if input value diesnt have enough chars
          $suggestionsContainer.setAttribute('aria-hidden', true);
        } else if (inputValue !== oldValue) {
          clearTimeout(typingTimer);
          // Make API request if there is a new input value
          typingTimer = setTimeout(() => {
            const apiParamUrl = `${config.apiUrl}?eID=rsmbstsitebst21&q=${inputValue}&rows=${config.maxResults}&language=${language || 'de'}`;
            const xhr = new XMLHttpRequest();

            xhr.open('GET', apiParamUrl);
            xhr.send();
            xhr.onload = () => {
              const data = JSON.parse(xhr.response) || null;
              const entities = data.entities.docs || null;
              const projects = data.projects.docs || null;
              const publications = data.publications.docs || null;
              const topicPages = data.topic_pages.docs || null;

              $suggestionsContainer.setAttribute('aria-hidden', false);

              // Show teaser suggestions container only if it has some content
              const colsHaveContent = projects.length > 0 || publications.length > 0 || topicPages.length > 0; // eslint-disable-line

              if (data && colsHaveContent) {
                $suggestionsContainer.querySelector('.search-bar__row--content').setAttribute('aria-hidden', false);
              } else {
                $suggestionsContainer.querySelector('.search-bar__row--content').setAttribute('aria-hidden', true);
              }

              // Create new suggestion teasers from the API
              const entitiesItems = createSearchBarLinkList(entities, 'entities');
              const projectsItems = createSearchBarTeaserList(projects, 'projects');
              const publicationsItems = createSearchBarTeaserList(publications, 'publications');
              const topicPagesItems = createSearchBarLinkList(topicPages, 'topic_pages');

              resetList($entitiesCol);
              resetList($projectsCol);
              resetList($publicationsCol);
              resetList($topicPagesCol);

              $entitiesCol.appendChild(entitiesItems);
              $projectsCol.appendChild(projectsItems);
              $publicationsCol.appendChild(publicationsItems);
              $topicPagesCol.appendChild(topicPagesItems);
            };
          }, config.doneTypingInterval);
        }

        oldValue = inputValue;
      });
    });
  });
};

export const resetSearchBar = ($searchBarWrapper) => {
  const $el = $searchBarWrapper;
  $el.querySelector('.input').value = '';
  $el.querySelector('.search-bar__suggestions').setAttribute('aria-hidden', true);
};

initSearchBars();

export default initSearchBars;
