import mustache from 'mustache';
import jump from 'jump.js';
import 'intersection-observer';

document.querySelectorAll('.js-jumplist').forEach(($jumplist) => {
  let clicked = false;
  let $clickedItem = null;

  const activeOnScroll = (entries) => {
    const $domJumpList = document.querySelector('.jumplist');
    let firstActive = null;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const $activeHeadline = $domJumpList.querySelector(`a[href='#${entry.target.headlineId}']`);

        // add active class to the first found intersecting block
        if ($activeHeadline && !firstActive && !clicked) {
          document.querySelectorAll('.jumplist__link.active').forEach($el => $el.classList.remove('active'));
          $activeHeadline.classList.toggle('active');
          firstActive = $activeHeadline;
        }

        if (clicked && $activeHeadline === $clickedItem) {
          clicked = false;
        }
      }
    });
  };

  const observer = new IntersectionObserver(
    entries => activeOnScroll(entries),
    { root: null, threshold: 0, rootMargin: '0px 0px -50% 0px' },
  );

  // Search pattern
  const searchPattern = $jumplist.getAttribute('data-search-pattern') || 'h2.headline--section';

  // Find all headlines
  const headlines = [];
  document.querySelectorAll(searchPattern).forEach(($headline) => {
    if ($headline.getAttribute('id')) {
      const altTitle = $headline.getAttribute('data-alt-title');
      headlines.push({
        href: `#${$headline.getAttribute('id')}`,
        title: altTitle || $headline.lastChild.textContent,
      });
    }

    const $contentBlock = $headline;
    $contentBlock.headlineId = $headline.id;
    observer.observe($contentBlock);
  });

  if (headlines.length === 0) {
    return;
  }

  // Render jumplist
  // eslint-disable-next-line no-param-reassign
  $jumplist.outerHTML = mustache.render($jumplist.innerHTML, { headlines });


  document.addEventListener('click', ($e) => {
    if ($e.target.matches('.jumplist__link')) {
      clicked = true;
      $clickedItem = $e.target;

      // Calculate offset for jump (header height)
      const $header = document.querySelector('.header--sticky');
      let jumpOffset = -200;
      if ($header) {
        jumpOffset = $header.getBoundingClientRect().height * -1;
      }

      jump($e.target.hash, {
        duration: 0,
        offset: jumpOffset,
        easing: false,
        callback: () => {
          document.querySelectorAll('.jumplist__link.active').forEach($el => $el.classList.remove('active'));
          $e.target.classList.toggle('active');
        },
      });
    }
  });
});
