const getUrlParamsObject = () => {
  const urlParamsString = window.location.search.substring(1);
  const urlRegex = '{"' + decodeURI(urlParamsString) // eslint-disable-line
    .replace(/"/g, '\\"').replace(/&/g, '","')
    .replace(/=/g, '":"') + '"}';

  let urlParamsObject = null;

  if (urlParamsString) {
    urlParamsObject = JSON.parse(urlRegex, (key, value) => {
      let output = null;

      if (key === '') {
        output = value;
      } else {
        output = decodeURIComponent(value);
      }

      return output;
    });
  }

  return urlParamsObject;
};

export default getUrlParamsObject;
