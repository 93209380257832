import h from 'hyperscript';
import icon from 'components/_particles/icon/icon';
import mediaLightbox from 'javascripts/globals/media-lightbox';
import initIframe from 'javascripts/globals/iframe';

class ExpandableFigure {
  constructor($el) {
    // Elements
    this.$el = $el;
    this.$media = this.$el.querySelector('.figure__media');
    this.$image = this.$el.querySelector('.image__img');
    this.expandText = 'Bild ausklappen';
    this.collapseText = 'Bild einklappen';
    this.expandTextEn = 'Expand image';
    this.collapseTextEn = 'Collapse image';
    this.lang = document.getElementsByTagName('html')[0].getAttribute('lang');

    // Constants
    this.IMAGE_HEIGHT_THRESHOLD = 740;

    if (this.lang === 'de') {
      this.LABELS = {
        expand: this.expandText,
        collapse: this.collapseText,
      };
    } else {
      this.LABELS = {
        expand: this.expandTextEn,
        collapse: this.collapseTextEn,
      };
    }

    // Init components
    if (this.isExpandable()) {
      this.initExpand();
    }
  }

  initExpand() {
    const isExpandedByDefault = this.$el.classList.contains('figure--expanded');

    // Add class
    this.$el.classList.add('figure--expandable');

    // Generate label
    const $label = h(
      'span.figure__expand-button-label',
      isExpandedByDefault ? this.LABELS.collapse : this.LABELS.expand,
    );

    // Generate icon
    const $icon = icon({
      icon: 'dropdown',
      classes: ['figure__expand-button-icon'],
    });

    // Generate expand button
    this.$expandButton = h(
      'button.figure__expand-button',
      {
        type: 'button',
        onclick: () => {
          const isExpanded = this.$expandButton.getAttribute('aria-expanded') === 'true';
          this.$el.classList[isExpanded ? 'remove' : 'add']('figure--expanded');
          this.$expandButton.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
          $label.textContent = isExpanded ? this.LABELS.expand : this.LABELS.collapse;
        },
        attrs: {
          'aria-expanded': isExpandedByDefault ? 'true' : 'false',
        },
      },
      $icon,
      $label,
    );

    // Insert button
    this.$el.appendChild(this.$expandButton);
  }

  isExpandable() {
    return this.getMediaType() === 'image' && this.getImageHeight() > this.IMAGE_HEIGHT_THRESHOLD;
  }

  getImageHeight() {
    return parseInt(this.$el.querySelector('.image__img').getAttribute('height') || 0, 10);
  }

  getMediaType() {
    let type = null;
    const $el = this.$el.querySelector('.figure__media');

    if ($el.classList.contains('figure__media--video')) {
      type = 'video';
    } else if ($el.classList.contains('figure__media--soundcloud')) {
      type = 'audio';
    } else {
      type = 'image';
    }

    return type;
  }
}

document.querySelectorAll('.figure').forEach($el => new ExpandableFigure($el));

const initFigureLightbox = () => {
  document.querySelectorAll('.js-media-lightbox').forEach(($el) => {
    const $trigger = $el.querySelector('.js-lightbox-link');

    if ($trigger) {
      $trigger.addEventListener('click', (e) => {
        e.preventDefault();
        mediaLightbox($el);
      });
    }
  });
};

initFigureLightbox();

document.body.querySelectorAll('.figure__iframe-wrapper').forEach(($el) => {
  initIframe($el);
});
