document.querySelectorAll('.js-breadcrumb').forEach(($breadcrumb) => {
  let width = 0;

  const $container = $breadcrumb.querySelector('.breadcrumb__inner');
  const $items = $breadcrumb.querySelectorAll('.breadcrumb__list-item');

  $items.forEach(($item) => {
    width += parseInt($item.offsetWidth, 10);
  });

  $container.scrollLeft += width;
});
