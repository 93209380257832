import Masonry from 'masonry-layout';

document.querySelectorAll('.masonry__items').forEach(($el) => {
  // eslint-disable-next-line no-unused-vars
  const masonry = new Masonry($el, {
    columnWidth: '.masonry__items-grid-sizer',
    gutter: '.masonry__items-gutter-sizer',
    itemSelector: '.masonry__item',
    percentPosition: true,
    transitionDuration: '0.2s',
    stagger: 20,
  });
});
