import mediaLightbox from 'javascripts/globals/media-lightbox';

const initMediaTeaserLightbox = () => {
  document.querySelectorAll('.js-media-teaser').forEach(($el) => {
    const $trigger = $el.querySelector('.headline__link');

    if ($trigger) {
      $trigger.addEventListener('click', (e) => {
        e.preventDefault();
        mediaLightbox($el);
      });
    }
  });
};

initMediaTeaserLightbox();
