import 'van11y-accessible-tab-panel-aria/dist/van11y-accessible-tab-panel-aria.min';
import Scrollable from 'javascripts/utils/scrollable';

document.querySelectorAll('.js-link-tabs').forEach(($linkTabs) => {
  // Init scrollable
  const scrollableInstance = new Scrollable(
    $linkTabs,
    $linkTabs.querySelector('.link-tabs__nav'),
    $linkTabs.querySelector('.link-tabs__nav-wrapper'),
    'link-tabs__button',
  );

  // Update slider on image loads
  $linkTabs.addEventListener('lazyloaded', () => scrollableInstance.scroll());
});
