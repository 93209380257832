import lightbox from 'javascripts/globals/lightbox';
import initIframe from 'javascripts/globals/iframe';
import initSlider from 'javascripts/globals/slider';
import templateResolver from 'javascripts/utils/media-lightbox-template-resolver';

const mediaLightbox = ($el) => {
  const id = $el.getAttribute('data-id');
  const dataGallery = JSON.parse($el.getAttribute('data-lightbox'));
  const $lightboxContent = dataGallery ? templateResolver({ sliderItems: dataGallery }) : '';

  // Init and open lightbox
  lightbox($lightboxContent, { id: id, fadeIn: true, transparent: true }); // eslint-disable-line

  const $lightbox = document.body.querySelector(`#lightbox-${id}`);

  document.querySelectorAll('.js-swiper').forEach(($swiperOuter) => {
    initSlider($swiperOuter);
  });

  $lightbox.querySelectorAll('.figure__iframe-wrapper').forEach(($iframe) => {
    initIframe($iframe);
  });
};

export default mediaLightbox;
