import h from 'hyperscript';
import getUrlParamsObject from 'javascripts/utils/get-url-params-object';
import getContentFromAPI, { defaultParams } from './list-content.api';
import {
  config,
  detectItemType,
  show,
  hide,
  updateLoadMoreButton,
} from './list-content.helper';

const initialParams = Object.assign({}, defaultParams, getUrlParamsObject());
let totalResults = null;

// Only change in dev mode!
let ix = 0;
const errorForFirstXLoads = 0;

const updateResultsCounter = (params, $listContainer, loadMore = false, api = null) => {
  const $counter = $listContainer.querySelector(`.${config.listCounterClass}`);
  const $counterNumber = $listContainer.querySelector(`.${config.listCounterNumberClass}`);
  const $loadMoreButton = $listContainer.querySelector(`.${config.loadMoreButtonClass}`) || null;

  if (!loadMore && $counterNumber) {
    // We need to modify API params to get the results
    const type = initialParams.type === 'search' ? 'sum-total' : 'sum-total-tag';
    const paramsForCounter = {
      ...params,
      docsOnly: false,
      // type: 'sum-total',
      type,
      page: '',
      itemsPerPage: '',
    };

    hide($counter);

    getContentFromAPI(paramsForCounter, api, (err, data) => {
      if (err) {
        $counterNumber.innerHTML = '0';
      } else if (data) {
        totalResults = data;
        $counterNumber.innerHTML = data;
        show($counter);
        updateLoadMoreButton($loadMoreButton, params.page, params.itemsPerPage, totalResults);
      }
    });
  } else if (loadMore && $counterNumber) {
    totalResults = Number($counterNumber.innerHTML);
    updateLoadMoreButton($loadMoreButton, params.page, params.itemsPerPage, totalResults);
  }
};

const prepareListContent = (params, $listContainer, loadMore = false, api = null) => {
  const $resultsContainer = $listContainer.querySelector(`.${config.listWrapperClass}`);
  const $loadMoreButton = $listContainer.querySelector(`.${config.loadMoreButtonClass}`) || null;
  const $searchBarInput = $listContainer.querySelector(`.${config.searchBar.class} .input`);
  const searchBarValue = $searchBarInput ? $searchBarInput.value : null;

  if (searchBarValue) {
    initialParams.q = searchBarValue;
  }

  if (!loadMore) {
    $resultsContainer.style.opacity = 0.25;
  } else {
    hide($loadMoreButton);
  }

  updateResultsCounter(params, $listContainer, loadMore, api);

  getContentFromAPI(params, api, (err, data, status) => {
    if (!loadMore) {
      $resultsContainer.style.opacity = 0;
    }

    if (err || data.trim().length === 0 || ix < errorForFirstXLoads) {
      let $errorContainer = null;

      if (initialParams.q && initialParams.q.length > 0) {
        $errorContainer = h('div.list-content__error',
          h('h3.headline.headline--2', `Unter dem Suchbegriff „${initialParams.q}“ wurde kein Ergebnis gefunden.`));
      } else {
        console.log(err); // eslint-disable-line
        $errorContainer = h('div.list-content__error',
          h('h3.headline.headline--2', 'Ein Fehler ist aufgetreten.'),
          h('dl.list-content__meta',
            h('dt.list-content__meta-label', 'Status:'),
            h('dd.list-content__meta-status', `${status}`),
            h('dt.list-content__meta-label', 'Error:'),
            h('dd.list-content__meta-status', `${err}`)),
          h('button.button.list-content__button-reload', {
            onclick: () => { prepareListContent(params, $listContainer, loadMore, api); },
          }, h('span.button__text', 'Suche erneut ausführen')));
      }

      hide($loadMoreButton);

      setTimeout(() => {
        if (!loadMore) {
          $resultsContainer.innerHTML = '';
          $resultsContainer.style.opacity = 1;
        }

        $resultsContainer.appendChild($errorContainer);
      }, 200);
    } else if (data) {
      if (!loadMore) {
        setTimeout(() => {
          $resultsContainer.innerHTML = data;
          $resultsContainer.style.opacity = 1;
        }, 200);
      } else {
        const $newContentWrapper = h('div.teaser-list__load-more-content', { 'data-page': Number(params.page) });

        updateLoadMoreButton($loadMoreButton, params.page, params.itemsPerPage, totalResults);

        $resultsContainer.appendChild($newContentWrapper);
        $newContentWrapper.innerHTML = data;
        $newContentWrapper.style.opacity = 1;
      }
    }
    ix += 1;
  });
};

const loadMoreClickHandler = ($el, api = null) => {
  initialParams.page = Number(initialParams.page) + 1;

  prepareListContent(initialParams, $el, true, api);
};

const searchBarHandler = ($el, dynamicFilters, api = null) => {
  const eventList = ['paste', 'cut', 'keyup'];
  const $searchBar = $el.querySelector(`.${config.searchBar.class}`);
  const $submitButton = $searchBar.querySelector('button[type=submit]');

  let typingTimer;
  let oldValue;

  if (dynamicFilters) {
    eventList.forEach((eventEl) => {
      $searchBar.addEventListener(eventEl, (e) => {
        const inputValue = e.target.value; // encodeURIComponent(e.target.value.trim());

        if ((inputValue.length >= config.searchBar.minChars) && (inputValue !== oldValue)) {
          clearTimeout(typingTimer);
          // Make API request if there is a new input value
          typingTimer = setTimeout(() => {
            // Set new paramater into var 'pageUrl'
            initialParams.q = inputValue;

            prepareListContent(initialParams, $el, false, api);
          }, config.searchBar.doneTypingInterval);

          oldValue = inputValue;
        }
      });
    });

    $submitButton.addEventListener('click', (event) => {
      event.preventDefault();
    });

    $el.addEventListener('keyup', (event) => {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  } else {
    $submitButton.addEventListener('click', (event) => {
      const inputValue = encodeURIComponent($searchBar.querySelector('.input').value.trim());
      const $filterSubmitButton = $el.querySelector('.list-content__filter-button');

      event.preventDefault();
      initialParams.q = inputValue;
      $filterSubmitButton.click();
    }, false);

    $el.addEventListener('keyup', (event) => {
      // Number 13 is the "Enter" key on the keyboard
      if (event.keyCode === 13) {
        event.preventDefault();
        $submitButton.click();
      }
    });
  }
};

const paramItemClickHandler = ($listContainer, event, dynamicFilters = false, api = null) => {
  const $item = event.target;
  const newParams = JSON.parse($item.getAttribute('data-parameter').replace(/(&quot;)/g, '"'));
  const pageUrl = new URL(window.location.href);
  const isItemSelected = $item.getAttribute('aria-selected') === 'true';
  const itemType = detectItemType($item);
  const typeClass = config.itemParamsClasses[`${itemType}`];
  const typeClassActive = `${typeClass}--active`;

  if (newParams) {
    if (itemType === 'checkbox') {
      Object.keys(newParams).forEach((newParamsKey) => {
        if (!isItemSelected) { // Item was not selected before
          $item.setAttribute('aria-selected', 'true');
          // Add parameter
          if (!initialParams[newParamsKey]) {
            // Create parameter if it doesnt exist yet
            initialParams[newParamsKey] = newParams[newParamsKey] || '';
          } else if (Array.isArray(initialParams[newParamsKey])) {
            // Parameter already exist. Create array of multiple values
            // If existing parameter is an array, then push new value to it.
            initialParams[newParamsKey].push(newParams[newParamsKey]);
          } else {
            // If existing parameter is not an array, then create one with new and old value
            initialParams[newParamsKey] = [newParams[newParamsKey], initialParams[newParamsKey]];
          }
        } else { // Item was selected before
          $item.setAttribute('aria-selected', 'false');
          // Remove parameter
          if (Array.isArray(initialParams[newParamsKey])) {
            // Remove value from array
            if (initialParams[newParamsKey].length > 1) {
              for (let i = 0; i < initialParams[newParamsKey].length; i += 1) {
                if (initialParams[newParamsKey][i] === newParams[newParamsKey]) {
                  initialParams[newParamsKey].splice(i, 1);
                }
                // Convert array into string if it contains only one value
                if (initialParams[newParamsKey].length === 1) {
                  // eslint-disable-next-line
                  initialParams[newParamsKey] = initialParams[newParamsKey][0];
                }
              }
            } else {
              delete initialParams[newParamsKey];
              pageUrl.searchParams.delete([newParamsKey]);
            }
          } else {
            // Remove parameter
            delete initialParams[newParamsKey];
            pageUrl.searchParams.delete([newParamsKey]);
          }
        }
      });
    }

    if (itemType === 'link' || itemType === 'sort') {
      event.preventDefault();

      if (!isItemSelected) {
        const allItemsOfSameType = $listContainer.querySelectorAll(`.${config.itemParamsClasses[`${itemType}`]}`);

        initialParams.page = 1;

        // Activate clicked item
        allItemsOfSameType.forEach(($itemOfSameType) => {
          $itemOfSameType.classList.remove(typeClassActive);
          $itemOfSameType.setAttribute('aria-selected', 'false');
        });

        $item.classList.add(typeClassActive);
        $item.setAttribute('aria-selected', 'true');

        Object.keys(newParams).forEach((newParamsKey) => {
          initialParams[newParamsKey] = newParams[newParamsKey] || '';
        });

        if (itemType === 'sort') {
          const $labelContainer = $listContainer.querySelector(`.${config.sortDropdownLabelClass}`);
          $labelContainer.innerHTML = `: ${event.target.innerHTML}`;
        }

        if (dynamicFilters) {
          prepareListContent(initialParams, $listContainer, false, api);
        }
      }
    }
  }

  // Add params to search params of page url
  Object.keys(initialParams).forEach((param) => {
    // eslint-disable-next-line
    if (config.excludeParamsFromUrl.indexOf(param) <= -1) {
      if (initialParams[param] && initialParams[param].length > 0) {
        pageUrl.searchParams.set(param, initialParams[param]);
      } else {
        pageUrl.searchParams.delete(param);
      }
    }
  });
};

const initListContentHandler = ($el) => {
  const dynamicFilters = ($el.getAttribute('data-dynamicfilters') === 'true');
  const $parameterItems = $el.querySelectorAll('[data-parameter]');
  const $loadMoreButton = $el.querySelector(`.${config.loadMoreButtonClass}`);
  const $searchBar = $el.querySelector(`.${config.searchBar.class}`);
  const api = $el.getAttribute('data-api');

  let globalParameter = $el.getAttribute('data-parameter');

  if (globalParameter) {
    globalParameter = JSON.parse($el.getAttribute('data-parameter').replace(/(&quot;)/g, '"'));

    Object.keys(globalParameter).forEach((key) => {
      initialParams[key] = globalParameter[key];
    });

    console.log('initialParams + globalParams: ', initialParams); // eslint-disable-line
  }

  if ($loadMoreButton) {
    $loadMoreButton.addEventListener('click', (e) => {
      e.preventDefault();
      loadMoreClickHandler($el, api);
    });
  }

  if ($searchBar) {
    searchBarHandler($el, dynamicFilters, api);
  }

  if ($parameterItems) {
    $parameterItems.forEach(($item) => {
      $item.addEventListener('click', (event) => {
        paramItemClickHandler($el, event, dynamicFilters, api);
      });
    });
  }


  if (!dynamicFilters) {
    const $inputField = $searchBar.querySelector('.input');
    const $hiddenQueryField = $el.querySelector('.list-filter__query');

    $inputField.addEventListener('change', () => {
      const inputValue = encodeURIComponent($inputField.value.trim());

      $hiddenQueryField.setAttribute('value', inputValue);
    });

    // const $submitButton = $el.querySelector('.list-content__filter-button');

    // $submitButton.addEventListener('click', () => {
    //   const inputValue = encodeURIComponent($searchBar.querySelector('.input').value.trim());
    //   const hiddenQueryField = $el.querySelector('.list-filter__query');

    //   if (inputValue.length > 0) {
    //     hiddenQueryField.setAttribute('value', inputValue);
    //     console.log(hiddenQueryField.value)
    //   } else {
    //     hiddenQueryField.setAttribute('value', '');
    //     console.log(null)
    //   }

    //   // e.preventDefault();
    //   // window.location.href = pageUrl.href;
    // }, false);
  }
};

export default initListContentHandler;
